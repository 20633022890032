import { TeaseRowContentsEntry } from "frontend/contentful/schema/blocks";
import { ContentTeaseEntry } from "frontend/contentful/schema/primitives";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { TeaseRow } from "design-system/components/blocks/tease-row/tease-row";
import { createComponentHeaderProps } from "frontend/contentful/components/primitives/component-header.props";
import { createContentTeaseProps } from "frontend/contentful/components/blocks/teases/content-tease.props";
import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import { useContentful } from "frontend/hooks/use-contentful";
import { LazyTeaseRowTease } from "design-system/components/blocks/tease-row/tease-row-tease";
import { Theme } from "design-system/utils/theme";

interface TeaseRowOptions {
  headerAbove?: boolean;
}

const themeMap: Array<Theme> = ["red", "black", "blue", "crimson", "purple"];
const ignoredThemeMap: Array<Theme> = ["dark", "light", "white"];

export const ContentfulTeaseRowContents = createContentfulComponent<
  TeaseRowContentsEntry,
  TeaseRowOptions
>(({ entry, options }) => {
  const { getEntries } = useContentful();

  const teases: Array<LazyTeaseRowTease> = [];

  const headerAbove = options?.headerAbove ?? false;
  const headerContent = createComponentHeaderProps(entry.fields.header);

  getEntries(entry.fields.contentTeases)?.forEach((tease, i) => {
    if (isContentType<ContentTeaseEntry>("contentTease", tease)) {
      const props = createContentTeaseProps(tease);

      if (props) {
        if (
          entry.fields.teaseThemeConsistent ||
          entry.fields.teaseThemeConsistent === undefined ||
          entry.fields.teaseThemeConsistent === null
        ) {
          props.theme = entry.fields.teaseTheme!;
        } else {
          if (
            !entry.fields.teaseTheme ||
            ignoredThemeMap.includes(entry.fields.teaseTheme)
          ) {
            props.theme = themeMap[i % themeMap.length];
          } else {
            const startingTheme = themeMap.indexOf(entry.fields.teaseTheme);
            props.theme = themeMap[(startingTheme + i) % themeMap.length];
          }
        }

        if (tease.fields.media) props.theme = undefined;
        if (entry.fields.teaseSize === "small") props.isSmall = true;

        teases.push({ name: "ArticleTease", props });
      }
    }
  });

  return (
    <TeaseRow
      teases={teases}
      wrap={entry.fields.wrap ? "wrap" : "no-wrap"}
      align={entry.fields.alignment}
      teaseStyle={"content"}
      teasesPerRow={entry.fields.teasesPerRow}
      headerContent={headerContent}
      HeadingLevel={headerAbove ? "h3" : "h2"}
      HeadingLevelTease={
        headerAbove
          ? entry.fields.header
            ? "h4"
            : "h3"
          : entry.fields.header
            ? "h3"
            : "h2"
      }
    />
  );
});
