import { Suspense } from "react";
import { ErrorBoundary } from "design-system/components/system/error-boundary/error-boundary";
import { useLazyComponents } from "./use-lazy-components";
import type {
  ComponentLibrary,
  ComponentLibraryProps,
} from "design-system/components";

export type LazyComponentProps = ComponentLibraryProps;
export type ExtractLazyComponent<N extends keyof ComponentLibrary> = Extract<
  LazyComponentProps,
  { name: N }
>;

export function LazyComponent({
  name,
  props,
  children,
}: LazyComponentProps): JSX.Element | null {
  const { getLazyComponentByName } = useLazyComponents();

  const Component = getLazyComponentByName(name);

  if (Component) {
    return (
      <ErrorBoundary>
        <Suspense>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          <Component {...((props || {}) as any)}>
            {children &&
              (Array.isArray(children) ? (
                <LazyComponents components={children} />
              ) : (
                <LazyComponent {...children} />
              ))}
          </Component>
        </Suspense>
      </ErrorBoundary>
    );
  }

  console.error(`ERROR: could not find lazy component ${name}`);

  return null;
}

interface LazyComponentsProps {
  components?: Array<LazyComponentProps>;
}

export function LazyComponents({ components }: LazyComponentsProps) {
  if (!components || !Array.isArray(components)) return null;

  return (
    <>
      {components.map((component, i) => (
        <LazyComponent key={i} {...component} />
      ))}
    </>
  );
}
